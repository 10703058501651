import appStore                   from "@/stores/appStore";
import { EllipsisOutlined }       from "@ant-design/icons";
import { ShopOutlined }           from "@ant-design/icons";
import Button                     from "@widesk-ui/components/Button";
import View                       from "@widesk-ui/components/View";
import { observer }               from "mobx-react";
import FileImage                  from "./FileImage";
import FileModel                  from "@/models/services/file/FileModel";
import ShopEntertainmentModel     from "@/models/services/shop/EntertainmentModel";
import { ReactNode }              from "react";
import { navigate }               from "@widesk-ui/hooks/useNavigate";

type SiderSelectEntertainmentItemProps = { 
	selected?: boolean, 
	title: string, 
	id: string, 
	img?: FileModel | ReactNode | null,
};

const SiderSelectEntertainmentItem = ({ selected, title, id, img }: SiderSelectEntertainmentItemProps) => {
	return (
		<Button
			title={title}
			tooltipProps={{ placement: 'right' }}
			style={{ padding: 0, height: 'auto', opacity: selected ? undefined : .4 }}
			onClick={() => navigate('/entertainments/' + id + '/home')}
			type="primary"
		>
			{img instanceof FileModel ? (
				<FileImage preview={false} size="middle" file={img} />
			) : img ? (
				img
			) : (
				<EllipsisOutlined />
			)}
		</Button>
	);
};

const SiderSelectEntertainment = observer(function () {
	const { 
		entertainment, 
		entertainmentCollection, 
		entertainmentFileLogoCollection 
	} = appStore;

	return (
		<View 
			width="50px"
			style={{ flexShrink: 0 }}
			gap="xs"
			padding={4}
			className="main-menu__buttons"
		>
			<SiderSelectEntertainmentItem
				selected={entertainment === 'all'}
				title="Toutes les campagnes"
				id="all" 
				img={<ShopOutlined />}
			/>
			{entertainmentCollection
				.map(value => (
					<SiderSelectEntertainmentItem
						key={value.id}
						selected={entertainment instanceof ShopEntertainmentModel && value.id === entertainment?.id}
						title={value.label}
						id={value.id} 
						img={entertainmentFileLogoCollection.find(file => file.typeSource.source.entityUrn === value.urn)}
					/>
				))
			}
		</View>
	);
});

export default SiderSelectEntertainment;
