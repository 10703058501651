import doc                from '@widesk-core/decorators/doc';
import model              from '@widesk-core/decorators/model';
import ShopServiceModel   from '@/models/ShopServiceModel';
import TimestampAble      from '@widesk-core/models/traits/TimestampAble';
import Blamable           from '@/models/traits/Blamable';
import Translatable       from '@widesk-core/models/traits/Translatable';
import EntertainmentModel from '@models/shop/EntertainmentModel';
import WithEntertainmentPath from '@/models/mixins/withEntetainmentPath';

@model.urnResource('taxonomy')
@doc.path('/taxonomies/{?id}')
export default class TaxonomyModel extends Blamable(TimestampAble(WithEntertainmentPath(Translatable(ShopServiceModel, {
    'label': true,
})))) {
    private declare _tsFilters: {
        'createdAt[after]': string | Date | Dayjs;
        'createdAt[before]': string | Date | Dayjs;
        'createdAt[strictly_after]': string | Date | Dayjs;
        'createdAt[strictly_before]': string | Date | Dayjs;
        'enabled': '0' | '1';
        'entertainment': id;
        'entertainment.entertainmentUrn': Urn;
        'slug': id;
        'translations.label': string;
        'updatedAt[after]': string | Date | Dayjs;
        'updatedAt[before]': string | Date | Dayjs;
        'updatedAt[strictly_after]': string | Date | Dayjs;
        'updatedAt[strictly_before]': string | Date | Dayjs;
    };

    private declare _tsSorts: {
        'createdAt': string;
        'id': string;
        'position': string;
        'translations.label': string;
        'updatedAt': string;
    };

    @doc.boolean declare enabled: boolean;
    @doc.model(EntertainmentModel) declare entertainment: EntertainmentModel;
    @doc.number declare position: number;
    @doc.string declare slug: string;
}