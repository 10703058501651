import DeliverableModel               from '@models/shop/DeliverableModel';
import DeliverableTransferStatusModel from '@models/shop/DeliverableTransferStatusModel';
import DeliverableTransferTypeModel   from '@models/shop/DeliverableTransferTypeModel';
import doc                            from '@widesk-core/decorators/doc';
import model                          from '@widesk-core/decorators/model';
import ShopServiceModel               from '@/models/ShopServiceModel';
import TimestampAble                  from '@widesk-core/models/traits/TimestampAble';
import Blamable                       from '@/models/traits/Blamable';
import ClientModel                    from '@models/shop/ClientModel';
import WithEntertainmentPath          from '@/models/mixins/withEntetainmentPath';

const customDocModel = doc.model;

@model.urnResource('deliverable_transfer')
@doc.path('/deliverable_transfers/{?id}')
export default class DeliverableTransferModel extends Blamable(TimestampAble(WithEntertainmentPath(ShopServiceModel))) {
	private declare _tsFilters: {
		'createdAt[after]': string | Date | Dayjs;
		'createdAt[before]': string | Date | Dayjs;
		'createdAt[strictly_after]': string | Date | Dayjs;
		'createdAt[strictly_before]': string | Date | Dayjs;
		'currentOwner': id;
		'currentOwner.userUrn': Urn;
		'deliverable': id;
		'deliverable.orderItemUnit.orderItem.order': id;
		'deliverable.orderItemUnit.orderItem.product.entertainment': id;
		'deliverableTransferStatus': id;
		'nextOwner': id;
		'nextOwner.userUrn': Urn;
		'updatedAt[after]': string | Date | Dayjs;
		'updatedAt[before]': string | Date | Dayjs;
		'updatedAt[strictly_after]': string | Date | Dayjs;
		'updatedAt[strictly_before]': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};	

	@doc.model(DeliverableModel) declare deliverable: DeliverableModel;
	@doc.model(DeliverableTransferStatusModel) declare deliverableTransferStatus: DeliverableTransferStatusModel;
	@doc.model(DeliverableTransferTypeModel) declare deliverableTransferType: DeliverableTransferTypeModel;
	@doc.string declare email: string;

	@customDocModel(ClientModel) declare currentOwner: ClientModel;
	@customDocModel(ClientModel) declare nextOwner: ClientModel;
}